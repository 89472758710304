.vc_custom_1411368076285 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #b9c1c6;
    background-position: 50% -87.5px;
}
.p{
    font-style: italic;
}
.vc_col-sm-6{
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
/* .wpb_wrapper{
    padding-left: 150px;
    padding-right: 150px;
} */
.container1 {
    position: relative;
    font-family: Arial;
    font-size: larger;
    font-style: italic;
    height: min-content;
    /* max-width: 13200px; */
  }
  
  .text-block {
    position: absolute;
    bottom: -3px;
    right: 100px;
    background-color: natural;
    color: rgb(241, 243, 243);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    font-weight: bolder;
    border-color: black;
  }
  /* .elementor-column.elementor-col-33, .elementor-column[data-col="33"] {
    width: 33.333%;
} */
.elementor-row {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
    padding: 20px;
}

.elementor-column-wrap {
    width: 100%;
}
.elementor-column, .elementor-column-wrap {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.section{
display: block;
}
.elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
    padding: 10px;
}
.elementor-column-wrap {
    width: 100%;
}
.elementor-column{
    position: relative;
    display: flex;
    min-height: 1px;


}
.elementor-widget-wrap {
    position: relative;
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: start;
    align-content: flex-start;
}
.elementor-widget-wrap>.elementor-element {
    width: 100%;
}
.elementor-section {
    position: relative;
}

.elementor-section .elementor-container {
    width: 1140px;
    display: flex; 
    margin-right: auto;
     margin-left: auto; 
    position: relative;
}
.elementor-element-populated {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #9e9ea05c;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 10px 10px 10px 10px;
}
.elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
    padding: 10px;
}
.elementor-image-box-wrapper:hover img{
    transform: scale(1.2);
transition: all 0.60s ease-in-out;
}


.cards {
    margin: 0 auto;
    max-width: 1000px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    grid-auto-rows: auto;
    gap: 45px;
    font-family: sans-serif;
    padding-top: 50px;
    width: 63%;
    
}

.cards * {
    box-sizing: border-box;
   
}

.card__image {
    width: 100%;
    height: 185px;
    object-fit: cover;
    display: block;
    /* border-top: 1px solid #a09c9c;
    border-right: 1px solid #a09c9c;
    border-left: 1px solid #a09c9c; */
   
}

.card__content {
    line-height: 1.5;
    font-size: 0.9em;
    padding: 15px;
    
    /* border-right: 1px solid #a09c9c;
    border-left: 1px solid #a09c9c;
    border-bottom: 1px solid #a09c9c; */
    text-align: center;
}

.card__content > p:first-of-type {
    margin-top: 0;
}

.card__content > p:last-of-type {
    margin-bottom: 0;
}
.card:hover{
    transform: scale(1.2);
transition: all 0.60s ease-in-out;
}
.card{
    box-shadow: 0 15px 55px -5px rgb(9 31 67 / 20%)
}