.elementor-widget-wrap{
    text-align: center;
    display: flex;
        position: relative;
        width: 100%;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-line-pack: start;
        align-content: flex-start;
   
}
.div, dl, dt, em, fieldset, font, form, h1, h2, h3, h4, h5, h6, html, iframe, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup, table, tbody, td, tfoot, th, thead, tr, tt, ul, var {
    border: 0;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}
.elementor-row{
    display: flex;
}
.img{
    width: 300px;
    height: 250px;
    margin-bottom: 20px;
}
.row{
    /* padding-left:64px; */
    /* padding-right: 236px; */
    padding-bottom: 0px;
}
/* .container5{
    margin-top: 80px;
} */
.section-head1{
    padding-top: 3em;
    /* padding-right: 15em; */
    padding-bottom: 2em;
    /* padding-left: 15em; */
}