footer{
    position: relative;
    bottom: 0;
   }
   .footer-distributed{
    background-color: #292c2f;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding-top: 15px;
    padding-right: 50px;
    padding-bottom: 0px;
    padding-left: 50px;
    margin-top: 80px;
   }
    
   .footer-distributed .footer-left,
   .footer-distributed .footer-center,
   .footer-distributed .footer-right{
    display: inline-block;
    vertical-align: top;
   }
    
   .footer-distributed .footer-left{
    width: 40%;
   }
    
   .footer-distributed h3{
    color:  #ffffff;
    font: normal 36px 'Cookie', cursive;
    margin: 0;
   }
    
   .footer-distributed h3 span{
    color:  #1205ce;
   }
    
    
   .footer-distributed .footer-links{
    color:  #ffffff;
    margin: 20px 0 12px;
    padding-left: 47px;
   }
   .footer-distributed .footer-links a{
    display:inline-block;
    line-height: 1.8;
    text-decoration: none;
    color:  inherit;
   }
    
   .footer-distributed .footer-company-name{
    color:  #8f9296;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
   }
    
    
   .footer-distributed .footer-center{
    width: 30%;
   }
    
   .footer-distributed .footer-center i{
    background-color:  #33383b;
    color: #ffffff;
    font-size: 25px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
   }
    
   .footer-distributed .footer-center i.fa-envelope{
    font-size: 17px;
    line-height: 38px;
   }
    
   .footer-distributed .footer-center p{
    display: inline-block;
    color: #ffffff;
    vertical-align: middle;
    margin:0;
   }
    
   .footer-distributed .footer-center p span{
    display:block;
    font-weight: normal;
    font-size:14px;
    line-height:2;
   }
    
   .footer-distributed .footer-center p a{
    color:  #5383d3;
    text-decoration: none;;
   }
    
   .footer-distributed .footer-right{
    width: 20%;
   }
    
   /* .footer-distributed .footer-company-about{
    line-height: 20px;
    color:  #92999f;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
   } */
    
   /* .footer-distributed .footer-company-about span{
    display: block;
    color:  #ffffff;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
   } */
    
   /* .footer-distributed .footer-icons{
    margin-top: 25px;
   }
    
   .footer-distributed .footer-icons a{
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color:  #33383b;
    border-radius: 2px;
    
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    
    margin-right: 3px;
    margin-bottom: 5px;
   } */
   @media (max-width: 880px) {
    
    .footer-distributed{
    font: bold 14px sans-serif;
    
    }
    
    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right{
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
    }
    
    .footer-distributed .footer-center i{
    margin-left: 0;
    }
    .main {
    line-height: normal;
    font-size: auto;
    }
    
   }
   hr:not([size]) {
    height: 1px;
    color: white;
}
.ast-small-footer {
    border-top-style: outset;
    border-top-width: 1px;
    border-top-color: #7a7a7a;
}
.ast-small-footer {
    line-height: 1.85714285714286;
    position: relative;
}
@media (min-width: 769px){
.ast-footer-overlay {
    padding-top: 2.66666em;
    padding-bottom: 2.66666em;
}}
.ast-footer-overlay {
    background-color: #292c2f;
    padding-top: 0em;
    padding-bottom: 1em;
}
@media (min-width: 922px){
.ast-container {
    max-width: 100%;
}}
