input[type=text], input[type=email] , input[type=number] {
    width: 100%;
    padding: 5px 22px;
    margin: 10px 0px;
    box-sizing: border-box;  
    border: 2px solid #ebecf3;
    height: 50px;
    border-radius: 5px;
    -webkit-appearance: none;

}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;}

textarea {
    width: 100%;
    height: 120px;
    padding: 15px 22px;
    box-sizing: border-box;
    border: 2px solid #ebecf3;
    border-radius: 2px;
    resize: none;  
    border-radius: 5px;
}

input[type=submit] {
    background: #0C2A8F;
    border: none;
    display: inline-block;
    color: #fff;
    font-weight: 600;
    padding: 15px 40px;
    border-radius: 5px;  
    

}
.form-container{
    /* text-align: cente;
    padding-right: 250px;
    padding-left: 200px;
    background-color: #dee1e4;
    max-width: 100%; */
    /* max-width: 1145px; */
    width: 75%;
    /* display: flex; */
    margin-right: auto;
    margin-left: auto;
    position: relative;
    padding-left: 28px;
    
}
label {
    display: inline-block;
    font-size: 100%;
    font-style: inherit;
    font-weight: bolder;
    color: #0C2A8F;
}
.contact-area overflow-hidden default-padding bg-gray{
    position: relative;
}
.form-row{
    width: 100%;
    height: 100%;
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
}
.contact-area form .col-lg-6 {
    padding: 0 8px;
  }
  .contact-area .content .heading {
    margin-bottom: 30px;
  }
  .col-log-6-info{
      padding-left: 20px;
      
  }