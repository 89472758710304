
.vc_custom_1411368076285 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #b9c1c6;
    background-position: 50% -87.5px;
}
.p{
    font-style: italic;
}
.vc_col-sm-6{
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
}
/* .wpb_wrapper{
    padding-left: 150px;
    padding-right: 150px;
} */
.container1 {
    position: relative;
    font-family: Arial;
    font-size: larger;
    font-style: italic;
    height: -webkit-min-content;
    height: min-content;
    /* max-width: 13200px; */
  }
  
  .text-block {
    position: absolute;
    bottom: -3px;
    right: 100px;
    background-color: natural;
    color: rgb(241, 243, 243);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    font-weight: bolder;
    border-color: black;
  }
  /* .elementor-column.elementor-col-33, .elementor-column[data-col="33"] {
    width: 33.333%;
} */
.elementor-row {
    width: 100%;
    display: -webkit-flex;
    display: flex;
}

.elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
    padding: 20px;
}

.elementor-column-wrap {
    width: 100%;
}
.elementor-column, .elementor-column-wrap {
    position: relative;
    display: -webkit-flex;
    display: flex;
}

.section{
display: block;
}
.elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
    padding: 10px;
}
.elementor-column-wrap {
    width: 100%;
}
.elementor-column{
    position: relative;
    display: -webkit-flex;
    display: flex;
    min-height: 1px;


}
.elementor-widget-wrap {
    position: relative;
    width: 100%;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-content: flex-start;
            align-content: flex-start;
}
.elementor-widget-wrap>.elementor-element {
    width: 100%;
}
.elementor-section {
    position: relative;
}

.elementor-section .elementor-container {
    width: 1140px;
    display: -webkit-flex;
    display: flex; 
    margin-right: auto;
     margin-left: auto; 
    position: relative;
}
.elementor-element-populated {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #9e9ea05c;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 10px 10px 10px 10px;
}
.elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
    padding: 10px;
}
.elementor-image-box-wrapper:hover img{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
transition: all 0.60s ease-in-out;
}


.cards {
    margin: 0 auto;
    max-width: 1000px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 45px;
    gap: 45px;
    font-family: sans-serif;
    padding-top: 50px;
    width: 63%;
    
}

.cards * {
    box-sizing: border-box;
   
}

.card__image {
    width: 100%;
    height: 185px;
    object-fit: cover;
    display: block;
    /* border-top: 1px solid #a09c9c;
    border-right: 1px solid #a09c9c;
    border-left: 1px solid #a09c9c; */
   
}

.card__content {
    line-height: 1.5;
    font-size: 0.9em;
    padding: 15px;
    
    /* border-right: 1px solid #a09c9c;
    border-left: 1px solid #a09c9c;
    border-bottom: 1px solid #a09c9c; */
    text-align: center;
}

.card__content > p:first-of-type {
    margin-top: 0;
}

.card__content > p:last-of-type {
    margin-bottom: 0;
}
.card:hover{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
transition: all 0.60s ease-in-out;
}
.card{
    box-shadow: 0 15px 55px -5px rgb(9 31 67 / 20%)
}
.features{
    background: white;
}
.container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.section-head {
    padding: 3em 0;
    padding-bottom: 2em;
    padding-left: 10%;
    padding-right: 10%;
}

*, :after, :before {
    box-sizing: border-box;
}

.text-left {
    text-align: center;
    margin-left: 40px;
    margin-top: 40px;
    color: #000000;
}
th {
    text-align: center;
    padding: 8px;
    background-color: #064b76;
    border: 1px solid #ddd;
    color: white;
}
tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
tr:nth-child(even) {
    background-color: #ddd;
}
table {
    text-align: center;
   
    border-collapse: collapse;
    width: 100%;
    border-collapse: separate;
    text-indent: 0;
    text-indent: initial;
    padding: 8px;
    border-spacing: 2px;
    padding-top: 0;
    padding-top: initial;
}
body, html {
    font-family: Roboto,sans-serif;
    font-size: 10%;
    background-color: #fff;
}
body {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: rgb(241, 229, 229);
    background-color: royalblue;
}
td {
    display: table-cell;
    vertical-align: inherit;
}
td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 4px;
}
.img{
    transition: all .35s ease-in-out;

}
tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-radius: 10px;
}
#border {
    border: 2px solid #ddd;
    height: 275px !important;
    /* width: 19% !important; */
}
@media (min-width: 992px){
.col-md-2{
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 0;
    padding-left: 0;
}}
.col-md-2:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
transition: all 0.50s ease-in-out;
}
.b {
    font-weight: 500;
    color: #064b76;
}
*, :after, :before {
    box-sizing: border-box;
}

/* b {
    font-weight: 10;
} */
@media (min-width: 1200px){
.h4{
    font-size: 20px;
}
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    margin-bottom: 10px;
    font-weight: 100;
    line-height: 1.1;
    color:  #064b76;
}


#truck_type_blank, .btn_search, .center, .form_bg #truck_type, .toggle_btn {
    text-align: center;
}
*, :after, :before {
    box-sizing: border-box;
}
user agent stylesheet
div {
    display: block;
}
body, html {
    font-family: Roboto,sans-serif;
    font-size: 100%;
    background-color: #fff;
}
body {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
}
.col-md-12 {
 
    float: left;
}
.payment-option {
    margin: 20px 0;
    -webkit-flex: column 1;
            flex: column 1;
}
#type1 {
    width: 95% !important;
}
.features{
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 14px;
        line-height: 1.42857143;
        color: #070707;
    }
   
    .centre{
        text-align: center;
    }

    .col-md-6{
        margin: 0;
        margin: initial;
    }
    .col-md-12{
        float: left;
    }
    .footer {
        background-color: #d3d6e2;
    }
    .p{color: black;}

    
    .img {
        vertical-align: middle;
        border: 0;
    }
    .p {
        margin: 0 0 10px;
    }
/* .container3{
    max-width: 80%;
}     */
/* @media (min-width: 768px){
.col-md-2 {
    flex: 0 0 auto;
    width: 18.666667%;
}} */
.elementor-widget-wrap{
    text-align: center;
    display: -webkit-flex;
    display: flex;
        position: relative;
        width: 100%;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-align-content: flex-start;
                align-content: flex-start;
   
}
.div, dl, dt, em, fieldset, font, form, h1, h2, h3, h4, h5, h6, html, iframe, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup, table, tbody, td, tfoot, th, thead, tr, tt, ul, var {
    border: 0;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}
.elementor-row{
    display: -webkit-flex;
    display: flex;
}
.img{
    width: 300px;
    height: 250px;
    margin-bottom: 20px;
}
.row{
    /* padding-left:64px; */
    /* padding-right: 236px; */
    padding-bottom: 0px;
}
/* .container5{
    margin-top: 80px;
} */
.section-head1{
    padding-top: 3em;
    /* padding-right: 15em; */
    padding-bottom: 2em;
    /* padding-left: 15em; */
}
.header{
    padding-top: 40px;
    text-align: center;
}
.elementor-section.elementor-section-boxed.elementor-container {
    max-width: 1140px;
}
.elementor-section .elementor-container {
    display: -webkit-flex;
    display: flex;
    margin-right: 13%;
    margin-left: 13%;
    position: relative;
}
.elementor *, .elementor :after, .elementor :before {
    box-sizing: border-box;
}
a, abbr, acronym, address, applet, big, blockquote, body, caption, cite, code, dd, del, dfn, div, dl, dt, em, fieldset, font, h1, h2, h3, h4, h5, h6, html, iframe, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup, table, tbody, td, tfoot, th, thead, tr, tt, ul, var {
    border: 0;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}
.elementor-row {
    width: 100%;
    display: -webkit-flex;
    display: flex;
}
/* @media (min-width: 768px){
.elementor-column.elementor-col-33, .elementor-column[data-col="33"] {
    width: 33.333%;
}} */
.elementor-column, .elementor-column-wrap {
    position: relative;
    display: -webkit-flex;
    display: flex;
}
.elementor-column {
    min-height: 1px;
}
.elementor-column-wrap {
    width: 100%;
}


a, abbr, acronym, address, applet, big, blockquote, body, caption, cite, code, dd, del, dfn, div, dl, dt, em, fieldset, font, h1, h2, h3, h4, h5, h6, html, iframe, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup, table, tbody, td, tfoot, th, thead, tr, tt, ul, var {
    border: 0;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}
.elementor-widget-wrap {
    position: relative;
    width: 100%;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-content: flex-start;
            align-content: flex-start;
}
.elementor-widget-wrap>.elementor-element {
    width: 100%;
}
.elementor-widget {
    position: relative;
}
@media (min-width: 768px){
.elementor-widget-icon-box.elementor-vertical-align-top .elementor-icon-box-wrapper {
    -webkit-align-items: flex-start;
            align-items: flex-start;
}}
.elementor-310 .elementor-element.elementor-element-eae4fe6.elementor-position-top .elementor-icon-box-icon {
    margin-bottom: 15px;
}
.elementor-element.elementor-element-eae4fe6.elementor-view-default .elementor-icon {
    fill: #0C2A8F;
    color: #0C2A8F;
    border-color: #0C2A8F;
}
.elementor-310 .elementor-element.elementor-element-eae4fe6 .elementor-icon {
    font-size: 50px;
}
.elementor-widget-icon-box.elementor-view-framed .elementor-icon, .elementor-widget-icon-box.elementor-view-default .elementor-icon {
    fill: var( --e-global-color-primary );
    color: var( --e-global-color-primary );
    border-color: var( --e-global-color-primary );
}
.elementor-icon {
    display: inline-block;
    line-height: 1;
    transition: all .3s;
    color: #818a91;
    font-size: 50px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.elementor-310 .elementor-element.elementor-element-eae4fe6 .elementor-icon i {
    -webkit-transform: rotate(
0deg
);
            transform: rotate(
0deg
);
}
.elementor-icon i, .elementor-icon svg {
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
}
.fa, .fas {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
.fa, .fab, .fad, .fal, .far, .fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.elementor-310 .elementor-element.elementor-element-eae4fe6 .elementor-icon-box-content .elementor-icon-box-title, .elementor-310 .elementor-element.elementor-element-eae4fe6 .elementor-icon-box-content .elementor-icon-box-title a {
    font-family: "Roboto",Sans-serif;
    font-size: 20px;
    font-weight: 600;
}
.elementor-310 .elementor-element.elementor-element-eae4fe6 .elementor-icon-box-content .elementor-icon-box-title {
    color: #0B2C76;
}
.entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6 {
    margin-bottom: 20px;
}

.entry-content h3, h3 {
    color: #0B2C76;
    font-size: 1.5em;
    line-height: 1.4;
}

.elementor-310 .elementor-element.elementor-element-eae4fe6 .elementor-icon-box-content .elementor-icon-box-description {
    color: #0B2C76;
}
.elementor-widget-icon-box .elementor-icon-box-content .elementor-icon-box-description {
    /* color: var( --e-global-color-text ); */
    font-family: var( --e-global-typography-text-font-family ),Sans-serif;
    font-weight:600;
}
.elementor-widget-icon-box .elementor-icon-box-description {
    margin: 0;
    padding-top: 20px;
}
.elementor-310 .elementor-element.elementor-element-be3874a.elementor-position-top .elementor-icon-box-icon {
    margin-bottom: 15px;
}

.elementor-icon {
    display: inline-block;
    line-height: 1;
    transition: all .3s;
    color: rgb(12, 42, 143);
    font-size: 50px;
    text-align: center;
}
.elementor-310 .elementor-element.elementor-element-be3874a.elementor-view-framed .elementor-icon, .elementor-310 .elementor-element.elementor-element-be3874a.elementor-view-default .elementor-icon {
    fill: #0C2A8F;
    color: #0C2A8F;
    border-color: #0C2A8F;
}
.elementor-310 .elementor-element.elementor-element-be3874a .elementor-icon {
    font-size: 50px;
}
.elementor-310 .elementor-element.elementor-element-be3874a .elementor-icon i {
    -webkit-transform: rotate(
0deg
);
            transform: rotate(
0deg
);
}
.elementor-icon i, .elementor-icon svg {
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
}
.fa, .fas {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
.fa, .fab, .fad, .fal, .far, .fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}
.elementor-icon elementor-animation{
    fill: #0C2A8F;
    color: #0C2A8F;
    border-color: #0C2A8F;
}
.span{
    border: 0;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

input[type=text], input[type=email] , input[type=number] {
    width: 100%;
    padding: 5px 22px;
    margin: 10px 0px;
    box-sizing: border-box;  
    border: 2px solid #ebecf3;
    height: 50px;
    border-radius: 5px;
    -webkit-appearance: none;

}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;}

textarea {
    width: 100%;
    height: 120px;
    padding: 15px 22px;
    box-sizing: border-box;
    border: 2px solid #ebecf3;
    border-radius: 2px;
    resize: none;  
    border-radius: 5px;
}

input[type=submit] {
    background: #0C2A8F;
    border: none;
    display: inline-block;
    color: #fff;
    font-weight: 600;
    padding: 15px 40px;
    border-radius: 5px;  
    

}
.form-container{
    /* text-align: cente;
    padding-right: 250px;
    padding-left: 200px;
    background-color: #dee1e4;
    max-width: 100%; */
    /* max-width: 1145px; */
    width: 75%;
    /* display: flex; */
    margin-right: auto;
    margin-left: auto;
    position: relative;
    padding-left: 28px;
    
}
label {
    display: inline-block;
    font-size: 100%;
    font-style: inherit;
    font-weight: bolder;
    color: #0C2A8F;
}
.contact-area overflow-hidden default-padding bg-gray{
    position: relative;
}
.form-row{
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
}
.contact-area form .col-lg-6 {
    padding: 0 8px;
  }
  .contact-area .content .heading {
    margin-bottom: 30px;
  }
  .col-log-6-info{
      padding-left: 20px;
      
  }
.NavbarItems{
    box-shadow: 0 0 0.8em rgb(10, 10, 10);
    background-color: #f1f1ee !important;
        height: 100px;
        display: -webkit-flex;
        display: flex; 
        -webkit-justify-content: center; 
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        font-size: 1.2rem;
        position: fixed;
        width: 100%;
        z-index: 2;
        
}

.navbar-logo{
    color:black;
    justify-self: start;
    margin-left: 20px;
    cursor:pointer;
    
}
 
.fa-react{
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu{
    display: grid;
    grid-template-columns:  repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align:center ;
    width: 70vw;
    -webkit-justify-content:end ;
            justify-content:end ;
    margin-right: 2rem;
    font-weight: bold;
    padding-top: 1%;
}

.nav-links {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
 /* background-color:#bcbfe9; */
 border-radius: 4px;
 transition: all 0.2s ease-out;
}
.fa-bars{
    color: rgb(8, 8, 8);
}

.nav-links-mobile{
    display: none;
}
.menu-icon{
    display: none;
}
@media screen and (max-width: 960px) {
    .NavbarItems{
        position: relative;
    }

    .nav-menu{
        display:-webkit-flex;
        display:flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all o.5s ease;
    }
    .nav-menu.active{
        background: #ffffff;
        left: 0;
        opacity: 1;
        transition: all o.5s ease;
        z-index: 2;
    
    }
    .nav-links{
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;

    }
    .nav-links:hover{
        background-color: #000000;
        border-radius: 0;

    }
    .nav-logo{
        position: absolute;
        top: 0;
        left: 0;
        -webkit-transform: translate(25%,50%);
                transform: translate(25%,50%);
    }

    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transform: translate(-100%,60%);
                transform: translate(-100%,60%);
        font-size: 1.8rem;
        cursor: pointer;

    }
    .fa-times{
        color: rgb(17, 14, 14);
        font-size: 2rem;
    }

    .nav-links-mobile{
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        -webkit-text-decoration: #fff;
                text-decoration: #fff;
        font-size: 1.5rem;
        color: #fff; 
        margin-top: 10px;
    }
    .nav-links-mobile:hover{
        background: #fff;
        color: #050518;
        transition: 250ms;
    }
    .Button{
        display: none;
    }
}
.navbar-brand {
    padding-top: 1.3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
}
footer{
    position: relative;
    bottom: 0;
   }
   .footer-distributed{
    background-color: #292c2f;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding-top: 15px;
    padding-right: 50px;
    padding-bottom: 0px;
    padding-left: 50px;
    margin-top: 80px;
   }
    
   .footer-distributed .footer-left,
   .footer-distributed .footer-center,
   .footer-distributed .footer-right{
    display: inline-block;
    vertical-align: top;
   }
    
   .footer-distributed .footer-left{
    width: 40%;
   }
    
   .footer-distributed h3{
    color:  #ffffff;
    font: normal 36px 'Cookie', cursive;
    margin: 0;
   }
    
   .footer-distributed h3 span{
    color:  #1205ce;
   }
    
    
   .footer-distributed .footer-links{
    color:  #ffffff;
    margin: 20px 0 12px;
    padding-left: 47px;
   }
   .footer-distributed .footer-links a{
    display:inline-block;
    line-height: 1.8;
    text-decoration: none;
    color:  inherit;
   }
    
   .footer-distributed .footer-company-name{
    color:  #8f9296;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
   }
    
    
   .footer-distributed .footer-center{
    width: 30%;
   }
    
   .footer-distributed .footer-center i{
    background-color:  #33383b;
    color: #ffffff;
    font-size: 25px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
   }
    
   .footer-distributed .footer-center i.fa-envelope{
    font-size: 17px;
    line-height: 38px;
   }
    
   .footer-distributed .footer-center p{
    display: inline-block;
    color: #ffffff;
    vertical-align: middle;
    margin:0;
   }
    
   .footer-distributed .footer-center p span{
    display:block;
    font-weight: normal;
    font-size:14px;
    line-height:2;
   }
    
   .footer-distributed .footer-center p a{
    color:  #5383d3;
    text-decoration: none;;
   }
    
   .footer-distributed .footer-right{
    width: 20%;
   }
    
   /* .footer-distributed .footer-company-about{
    line-height: 20px;
    color:  #92999f;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
   } */
    
   /* .footer-distributed .footer-company-about span{
    display: block;
    color:  #ffffff;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
   } */
    
   /* .footer-distributed .footer-icons{
    margin-top: 25px;
   }
    
   .footer-distributed .footer-icons a{
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color:  #33383b;
    border-radius: 2px;
    
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    
    margin-right: 3px;
    margin-bottom: 5px;
   } */
   @media (max-width: 880px) {
    
    .footer-distributed{
    font: bold 14px sans-serif;
    
    }
    
    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right{
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
    }
    
    .footer-distributed .footer-center i{
    margin-left: 0;
    }
    .main {
    line-height: normal;
    font-size: auto;
    }
    
   }
   hr:not([size]) {
    height: 1px;
    color: white;
}
.ast-small-footer {
    border-top-style: outset;
    border-top-width: 1px;
    border-top-color: #7a7a7a;
}
.ast-small-footer {
    line-height: 1.85714285714286;
    position: relative;
}
@media (min-width: 769px){
.ast-footer-overlay {
    padding-top: 2.66666em;
    padding-bottom: 2.66666em;
}}
.ast-footer-overlay {
    background-color: #292c2f;
    padding-top: 0em;
    padding-bottom: 1em;
}
@media (min-width: 922px){
.ast-container {
    max-width: 100%;
}}

