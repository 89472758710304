.NavbarItems{
    box-shadow: 0 0 0.8em rgb(10, 10, 10);
    background-color: #f1f1ee !important;
        height: 100px;
        display: flex; 
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        position: fixed;
        width: 100%;
        z-index: 2;
        
}

.navbar-logo{
    color:black;
    justify-self: start;
    margin-left: 20px;
    cursor:pointer;
    
}
 
.fa-react{
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu{
    display: grid;
    grid-template-columns:  repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align:center ;
    width: 70vw;
    justify-content:end ;
    margin-right: 2rem;
    font-weight: bold;
    padding-top: 1%;
}

.nav-links {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
 /* background-color:#bcbfe9; */
 border-radius: 4px;
 transition: all 0.2s ease-out;
}
.fa-bars{
    color: rgb(8, 8, 8);
}

.nav-links-mobile{
    display: none;
}
.menu-icon{
    display: none;
}
@media screen and (max-width: 960px) {
    .NavbarItems{
        position: relative;
    }

    .nav-menu{
        display:flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all o.5s ease;
    }
    .nav-menu.active{
        background: #ffffff;
        left: 0;
        opacity: 1;
        transition: all o.5s ease;
        z-index: 2;
    
    }
    .nav-links{
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;

    }
    .nav-links:hover{
        background-color: #000000;
        border-radius: 0;

    }
    .nav-logo{
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%,50%);
    }

    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%,60%);
        font-size: 1.8rem;
        cursor: pointer;

    }
    .fa-times{
        color: rgb(17, 14, 14);
        font-size: 2rem;
    }

    .nav-links-mobile{
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        text-decoration: #fff;
        font-size: 1.5rem;
        color: #fff; 
        margin-top: 10px;
    }
    .nav-links-mobile:hover{
        background: #fff;
        color: #050518;
        transition: 250ms;
    }
    .Button{
        display: none;
    }
}
.navbar-brand {
    padding-top: 1.3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
}